<template>
	<v-card class="mx-auto mb-5" max-width="1200" rounded="xl">
		<v-toolbar>
			<v-avatar size="45px" class="mr-2">
				<v-img alt="Avatar" :src="upload.uploader.imgURL" />
			</v-avatar>
			<v-toolbar-title>
				<div class="d-flex flex-column">
					<span class="title">{{ upload.uploader.fullname }}</span>
					<span class="subtitle-1">{{ upload.course }}</span>
				</div>
			</v-toolbar-title>
		</v-toolbar>

		<template>
			<v-card-title>{{ upload.title }}</v-card-title>
			<v-card-subtitle>
				<time :datetime="upload.timestamp">{{ humanTime }}</time>
			</v-card-subtitle>
			<v-card-text class="body-1" style="word-break: break-all"> Has uploaded {{ upload.filename }} </v-card-text>
		</template>
	</v-card>
</template>

<script>
export default {
	name: 'FeedUpload',
	props: {
		upload: {
			type: Object,
			required: true,
		},
	},
	computed: {
		humanTime() {
			return this.$moment(this.upload.timestamp).fromNow()
		},
	},
}
</script>
